import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { Link } from "gatsby"

import { EXTERNAL_LINKS, ROUTES } from "./Layout"
import DHBW_Logo from "../images/DHBW_Logo_neu.svg"
import useHeaderNavigation from "../hooks/useHeaderNavigation"
import MenuButton from "./MenuButton"

const Header = () => {
  const { showNav, setShowNav } = useHeaderNavigation()

  return (
    <header className="max-w-8xl h-24 mx-auto bg-red-200 px-4 xl:px-24 flex items-stretch justify-between text-white fixed z-40 right-0 left-0">
      <div className="flex items-center">
        <a
          href={EXTERNAL_LINKS[0].path}
          target="_blank"
          rel="noreferrer"
          aria-label="Zur Startseite der DHBW"
        >
          <img className="w-44 hover:invert" src={DHBW_Logo} alt="DHBW Logo" />
        </a>
      </div>

      <Link
        className="hidden md:flex items-center justify-center text-center text-3xl lg:text-4xl hover:text-black"
        to={ROUTES[0].path}
        onClick={() => setShowNav(false)}
        aria-label="Zur Startseite des Jahresberichts 2023 – 24"
      >
        Jahresbericht 2023 – 24
      </Link>

      <button
        className="2xl:min-w-[558px] flex justify-end 2xl:justify-center items-center 2xl:bg-gray-400 group"
        onClick={() => setShowNav(!showNav)}
        aria-label={showNav ? "Navigation schließen" : "Navigation öffnen"}
      >
        <div className="min-w-24 flex 2xl:w-full flex-col-reverse 2xl:flex-row justify-around items-center relative">
          <div className="2xl:min-w-44 text-2xl 2xl:text-4xl group-hover:text-black min-w-32">
            {showNav ? "Schließen" : "Navigation"}
          </div>
          <MenuButton
            showNav={showNav}
            classes="size-14 group-hover:stroke-black"
          />
          {showNav && (
            <nav className="w-screen sm:w-auto min-w-[100vw] sm:min-w-[492px] absolute top-[5.6rem] 2xl:top-[4.1875rem] 2xl:left-0 -right-4 sm:right-[calc(100%-8rem)] 2xl:right-0 h-[calc(100vh-9.4rem)] 2xl:h-[calc(100vh-8.9rem)] bg-gray-400 text-white z-40">
              <ul
                className="flex flex-col justify-end h-full md:pb-8 lg:pb-16 z-40"
                aria-label="Unterseiten"
              >
                {ROUTES.map((route, index) => (
                  <li
                    key={index}
                    className="text-3xl text-left px-4 sm:px-14 hover:bg-red-200 hover:text-white w-full"
                  >
                    <AniLink
                      fade
                      to={route.path}
                      className="block py-4 whitespace-nowrap text-2xl sm:text-3xl md:text-4xl text-right sm:text-left"
                      onClick={() => setShowNav(false)}
                    >
                      {route.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
              <div className="bg-gray-400 flex justify-between px-4 md:px-10">
                {EXTERNAL_LINKS.filter(
                  path => path.name !== "DHBW Startseite"
                ).map(({ name, path }, index) => (
                  <a
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`hover:bg-red-200 hover:text-white p-4 block h-full ${
                      index === EXTERNAL_LINKS.length - 1
                        ? "hidden sm:block"
                        : ""
                    }`}
                    key={name}
                  >
                    {name}
                  </a>
                ))}
              </div>
            </nav>
          )}
        </div>
      </button>
    </header>
  )
}

export default Header
