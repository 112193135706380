import React from "react"

import "../dist/style.css"
import Header from "./Header"
import Navigation from "./Navigation"
import Footer from "./Footer"
import useCurrentLocation from "../hooks/useCurrentLocation"

export const ROUTES = [
  { name: "Startseite", path: "/" },
  {
    name: "Grußwort der Präsidentin",
    path: "/grusswort-der-praesidentin/",
  },
  { name: "Das Jahr an der DHBW", path: "/das-jahr-an-der-dhbw/" },
  { name: "Die DHBW auf einen Blick", path: "/die-dhbw-auf-einen-blick/" },
  { name: "Studierende an der DHBW", path: "/studierende-an-der-dhbw/" },
  { name: "Mitarbeitende an der DHBW", path: "/mitarbeitende-an-der-dhbw/" },
  { name: "Das Präsidium der DHBW", path: "/das-praesidium-der-dhbw/" },
  { name: "Finanzen und Haushalt", path: "/finanzen-und-haushalt/" },
]

export const EXTERNAL_LINKS = [
  {
    name: "www.dhbw.de",
    path: "https://www.dhbw.de",
  },
  {
    name: "Impressum",
    path: "https://www.dhbw.de/impressum",
  },
  {
    name: "Datenschutz",
    path: "https://www.dhbw.de/datenschutz",
  },
  {
    name: "Barrierefreiheit",
    path: "https://www.dhbw.de/barrierefreiheit",
  },
]

const Layout = ({ children, location }) => {
  const isHomepage = useCurrentLocation(location)

  // needs 'React.cloneElement', to pass props to children. Otherwise it’s just {children}
  return (
    <div className="relative">
      {!isHomepage && <Header />}
      <main className="max-w-8xl mx-auto relative">
        {React.cloneElement(children, { location })}
        {!isHomepage && <Navigation location={location} />}
      </main>
      <Footer location={location} />
    </div>
  )
}

export default Layout
